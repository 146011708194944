<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="col-md-6">
            <div class="form-group">
              <label>Select week</label>
              <input type="week" class="form-control" v-model="selectedWeek" @change="getGeneratedPayout">
            </div>
          </div>
        </div>
      </div>

      <template v-if="payout && payout['payoutSummary']">
        <div class="card">
        <div class="card-body">
          <div class="text-center">
            <div class="row">
              <div class="col-md-6 col-xl-4">
                <div class="py-1">
                  <!--                <i class="fa fa-users fa-2x text-info"></i>-->
                  <h3 class="text-info"> {{payout['payoutSummary']['total_number_drivers_for_cost']}} </h3>
                  <p class="text-uppercase mb-1 font-13 font-weight-medium">
                    Total Drivers
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-xl-4">
                <div class="py-1">
                  <!--                <i class="fa fa-user-shield fa-2x text-warning"></i>-->
                  <h3 class="text-warning">  {{payout['payoutSummary']['total_cost'] | toCurrencyFormat}} </h3>
                  <p class="text-uppercase mb-1 font-13 font-weight-medium">
                    Total Cost
                  </p>
                </div>
              </div>
              <div class="col-md-6 col-xl-4">
                <div class="py-1">
                  <h3 class="text-success"> {{payout['payoutSummary']['total_income']  | toCurrencyFormat}} </h3>
                  <p class="text-uppercase mb-1 font-13 font-weight-medium">
                    Total Income
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
<!--        <b-card class="" >-->
<!--          -->
<!--        </b-card>-->
      </template>
      <template v-else>
        <div class="card">
          <div class="card-body">
            <div class="">
                <h4 class="card-title text-danger">There are no pending payouts for {{this.selectedWeek}}</h4>
            </div>
          </div>
        </div>
      </template>

      <div class="card">
        <div class="card-body">
          <Sidepanel @onDriverSelected="onDriverSelected" />
          <div class="inbox-rightbar">
            <!--              <Toolbar />-->
<!--           /////////////////////// CONTENT HERE -->
            <template v-if="driver">
              <div class="mt-0">
                <h5 class="font-18">
                  <span class="mr-3">{{driver['name']}} {{driver['other_names']}}</span>
                  <template v-if="driver['prospective_driver'] && driver['prospective_driver']['service_type']">
                    <span class="badge badge-dark p-1"> {{driver['prospective_driver']['service_type'] | serviceType}}</span>
                  </template>
                  <template v-else>
                    (No service selected)
                  </template>

                  <span class="ml-2">|</span>
                  <span class="ml-3">Accumulated Arrears = {{accumulatedArrears | toCurrencyFormat}}</span>

                </h5>
              </div>
              <hr>

<!--              content here-->
              <template v-if="payout && payout['payoutDetail']">
                <h5 class="card-title"> Summary </h5>
                <table class="table table-striped">
                  <tr>
                    <td>New accumulated arrears (when payout is confirmed)</td>
                    <td>{{newAccumulatedArrears | toCurrencyFormat}}</td>
                  </tr>
                </table>
                <hr>
                <b-card>
                  <h5 class="card-title"> Cost breakdown </h5>
                  <table class="table table-striped">
                    <tr>
                      <td>Total Cost</td>
                      <td>{{payout['payoutDetail']['driver_total_cost'] | toCurrencyFormat}}</td>
                    </tr>
                  </table>
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>Field name</th>
                      <th>Name displayed to driver</th>
                      <th>Field value</th>
                    </tr>
                    </thead>
                    <template v-if="payout['costBreakDown'].length > 0" v-for="cb in payout['costBreakDown']">
                      <tr>
                        <td>{{cb['fieldName']}}</td>
                        <td>{{cb['fieldNameDisplay']}}</td>
                        <td>{{cb['fieldValue']}}</td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="3">
                          No costs available
                        </td>
                      </tr>
                    </template>

                  </table>
                </b-card>
                <hr>
                <b-card>
                  <h5 class="card-title"> Income breakdown </h5>
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>Provider</th>
                      <th>Total field name</th>
                      <th>Total value</th>
                      <th>Calculated field value</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="payload in (payout['payoutDetail']['income_payload'] ? JSON.parse(payout['payoutDetail']['income_payload']) : [] )">
                      <tr>
                        <td>{{payload['name']}}</td>
                        <td>{{payload['totalFieldName']}}</td>
                        <template v-if="payload['hasTotalField']">
                          <td><span class="text-success">{{payload['totalFieldValue']}}</span></td>
                        </template>
                        <template v-else>
                          <td>N/A</td>
                        </template>
                        <td>
                          <template v-if="payload['hasTotalField']">
                            <span>{{payload['calculatedIncome']}}</span>
                          </template>
                          <template v-else>
                            <span class="text-success">{{payload['calculatedIncome']}}</span>
                          </template>

                        </td>

                      </tr>
                    </template>
                    </tbody>
                    <!--                  [{"providerId":"e232985f-2d39-45a0-bc89-c545f9cab413","name":"UBER","hasTotalField":true,"useProviderTotalField":true,"totalFieldName":"Total","totalFieldValue":"918.45","calculatedIncome":-60.7457}]-->

                  </table>
                </b-card>
                <b-card>
                  <h5 class="card-title"> Balance for {{this.selectedWeek}} </h5>
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>Total income for {{this.selectedWeek}}</th>
                      <th>Total cost for {{this.selectedWeek}}</th>
                      <th>Balance for {{this.selectedWeek}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>{{selectedWeekTotalIncome | toCurrencyFormat}}</td>
                      <td>{{selectedWeekTotalCost | toCurrencyFormat }}</td>
                      <td>{{selectedWeekBalance | toCurrencyFormat}}</td>
                    </tr>
                    </tbody>
                    <!--                  [{"providerId":"e232985f-2d39-45a0-bc89-c545f9cab413","name":"UBER","hasTotalField":true,"useProviderTotalField":true,"totalFieldName":"Total","totalFieldValue":"918.45","calculatedIncome":-60.7457}]-->
                  </table>
                  <h5 class="card-title"> Money to be transferred to bank on {{this.selectedWeek}} </h5>
                  <table class="table">
                    <tr>
                      <td>To be transferred to bank</td>
                      <template v-if="transferToBank > 0">
                        <td class="text-success">{{transferToBank | toCurrencyFormat}}</td>
                      </template>
                      <template v-else>
                        <td class="text-danger">{{transferToBank | toCurrencyFormat}}</td>
                      </template>

                    </tr>
                  </table>
                </b-card>
              </template>

            </template>
<!--           /////////////////////// END OF CONTENT HERE -->

          </div >

        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->

      <div class="row" v-if="payout && payout['payoutSummary']">
        <div class="col-md-12">
          <b-card class="text-center">
            <b-form-checkbox
                id="checkbox-1"
                v-model="usePush"
                name="checkbox-1"
                value="yes"
                unchecked-value="no"
                class="pb-4"
            >
              Send out push notifications
            </b-form-checkbox>
              <button v-if="payout['payoutSummary']['status'] === 'pending'" class="btn btn-dark mr-2" @click="deleteGeneratedPayout"> <i class="fas fa-ban"></i> Delete generated payout </button>
              <button class="btn btn-success mr-2" @click="confirmPayoutForWeek(payout['payoutSummary']['id'])"> <i class="fas fa-check-circle"></i> Confirm payout for {{selectedWeek}} </button>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {payoutService} from "../../../../apis/payout.service";
import Sidepanel from "./sidepanel";
import {Skeleton} from "vue-loading-skeleton";
import {financialAdministrationService} from "../../../../apis/financial.administration.service";
import moment from "moment";
import {confirm} from "../../../../utils/functions";

export default {
  name: "make-payout",
  components: {
    Sidepanel,
    Skeleton
  },
  data(){
    return  {
      driver: null,
      selectedWeek: '',
      data: null,
      selectedIncomeCostType: '',
      accumulatedArrears: '0.00',
      payout: null,
      usePush: 'no',
      transferToBank: 0.00,
      newAccumulatedArrears: 0.00,
      selectedWeekTotalIncome: '0.00',
      selectedWeekTotalCost: '0.00',
      selectedWeekBalance: '0.00',
    }
  },
  methods: {

    onDriverSelected(driver){
      console.log('driver: ', driver);
      this.driver = driver;
    },

    onFilterChanged(){
      console.log('filter changed called')
      this.fetchFinancialData();
    },

    getPreviousWeekInHtmlFormat(){
      const year = moment().year();
      const week = moment().week();
      return  year + '-W' + week.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      });
      // console.log('weekInHtmlFormat',weekInHtmlFormat)
    },

    confirmPayoutForWeek(payoutId){

      confirm('Confirm payout for ' + this.selectedWeek , () => {
        const payload = {
          'use_push': this.usePush,
          'payout_id': payoutId
        }

        this.$store.dispatch('showLoader')
        payoutService.confirmGeneratePayout(payload).then((data) => {
          this.$store.dispatch('hideLoader')

          if(!data.status){
            this.$store.dispatch('error', {message:data.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message:data.message, showSwal: true})
          this.getGeneratedPayout();

        })
      })

    },


    getArrearsForDriver(){
      if(!this.driver){
        return;
      }

      this.$store.dispatch('showLoader')
      payoutService.fetchArrears(this.driver['id']).then((data) => {
        this.$store.dispatch('hideLoader')

        if(!data.status){
          this.$store.dispatch('error', {message:data.message, showSwal: true})
          return;
        }

        this.accumulatedArrears = data['extra']['accumulated_amount'];

      })

    },

    getGeneratedPayout(){
      if(!this.driver){
        return;
      }
      if(this.selectedWeek === ''){
        return;
      }

      const payload = {
        'week': this.selectedWeek,
        'driver_user_id': this.driver['id'],
        'status': 'pending'
      }

      this.$store.dispatch('showLoader')
      payoutService.getGeneratePayout(payload).then((data) => {
        this.$store.dispatch('hideLoader')

        if(!data.status){
          this.$store.dispatch('error', {message:data.message, showSwal: true})
          return;
        }

        const p = data['extra']['payoutSummary'] ? data['extra'] : null;
        this.payout = {...p};
        if(data['extra']['payoutDetail']){
          this.newAccumulatedArrears = data['extra']['newAccumulatedArrears']
          this.transferToBank = data['extra']['transferToBank']
          this.selectedWeekTotalIncome = data['extra']['selectedWeekTotalIncome']
          this.selectedWeekTotalCost = data['extra']['selectedWeekTotalCost']
          this.selectedWeekBalance = data['extra']['selectedWeekBalance']
        }
        console.log('payout', this.payout)

      })

    },

    deleteGeneratedPayout(){

      confirm('Delete payout for ' + this.selectedWeek , () => {
        this.$store.dispatch('showLoader')

        const payload = {
          'week': this.selectedWeek
        }
        payoutService.deleteGeneratePayout(payload).then((data) => {
          this.$store.dispatch('hideLoader')

          if(!data.status){
            this.$store.dispatch('error', {message:data.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message: data.message, showSwal: true})
          this.getGeneratedPayout();

        })
      })

    },




  },
  watch: {
    driver: function (driver) {
      if(driver){
        this.getArrearsForDriver();
        this.getGeneratedPayout();
      }
    }
  },
  created() {
    this.$store.dispatch('setPageTitle','Make payout');
    this.selectedWeek = this.getPreviousWeekInHtmlFormat();
   // this.fetchPayoutStanding()
  }
}
</script>

<style scoped>

</style>